import React, { useEffect, useState } from 'react'
import StyledContainer from '../../../components/StyledContainer'
import { FaExpand } from 'react-icons/fa'
import { utils } from '../../../utils/utils'
import { SpinLoading } from '../../../assets/SpinLoading'

export const SalesPackageSeat = (({seatMatrix, seatFocus, setSeatFocus, loading, toggleFullScreen, isFullScreen, list}) => {
  const [orderList, setOrderList] = useState([])

  const isSoldTicketCd = (priceId) => {
    return utils.packageCell().find(r => r.code === priceId)
  }

  const getSeatStyle = (seat) => {
    return {
      cursor: seat?.priceId ? 'pointer' : 'default',
      backgroundColor: seat?.priceId ? isSoldTicketCd(seat?.priceId)?.color : utils.seatColor(seat),
      border: seat?.isCouponSeat ? '2px solid black' : null,
      padding: '4px',
      margin: '1px',
      textAlign: 'center',
      color: 'white',
      fontSize: '10px',
      flex: isFullScreen ? '0 0 22px' : '0 0 62px',
      width: isFullScreen ? '22px' : '62px',
      height: isFullScreen ? '22px' : '44px',
      transform: 'translate3d(0, 0, 0)'
    }
  }

  const getSoldPackageCd = (seat,tt) => {
    if (!seat?.SeatStatus) return

    const ticketCd = seatMatrix.flat().find(r => r?.priceId && (seat.SeatGroup === r.SeatGroup && seat.SeatNo === r.SeatNo))?.priceId

    return ticketCd
  }

  const sumSoldSeatCnt = () => {
    const data = seatMatrix?.flat()?.reduce((acc, cur) => {
      if (cur?.priceId) {
        const {priceId} = cur
        acc[priceId] = acc[priceId] || []
        acc[priceId].push(cur)
      }
      return acc
    }, {})

    // 키를 오름차순으로 정렬하고 새로운 객체 생성
    const sortedData = Object.keys(data)
    .sort((a, b) => a.localeCompare(b)) // 키를 오름차순으로 정렬
    .reduce((sortedAcc, key) => {
      sortedAcc[key] = data[key];
      return sortedAcc;
    }, {});

    setOrderList(sortedData);
  }

  const handleSeatFocus = (seat) => {
    if (seat?.SeatStatus !== 'PAID') return

    const TicketCd = getSoldPackageCd(seat)
    setSeatFocus(seatFocus === TicketCd ? null : TicketCd)
  }

  useEffect(() => {
    setOrderList([])
    if (seatMatrix?.flat().filter(r => r?.SeatStatus === 'PAID').length !== 0) {
      sumSoldSeatCnt()
    }
  }, [seatMatrix])

  return (
    <StyledContainer margin={!isFullScreen}>
      <div className="flex items-start justify-between">
        <div className="text-sm font-medium text-gray-700">
          좌석
        </div>
        {!loading && list?.length > 0 &&
          <div>
            <p className="flex justify-end gap-1 text-xs font-medium text-gray-500 mb-1">
              <span className={'text-gray-700'}>남은 좌석 {list[0]?.availSeatCount}</span>
              <span>/</span>
              <span>총 좌석 {list[0]?.totalSeatCount}</span>
            </p>
            <p className="flex justify-end gap-1 text-xs font-medium text-gray-400">
              <span>판매 좌석 {seatMatrix?.flat()?.filter(r => r?.priceId && !r?.isCouponSeat)?.length}</span>
              <span>/</span>
              <span>쿠폰 좌석 {seatMatrix?.flat()?.filter(r => r?.isCouponSeat)?.length}</span>
            </p>
          </div>
        }
      </div>

      {loading ?
        <div
          className="flex items-center justify-center w-full h-36">
          <SpinLoading width={'w-8'} height={'h-8'} textColor={'text-gray-500'}/>
        </div>
        :
        <div className="bg-white w-full">
          <button className={`my-4 ml-auto flex justify-end`} onClick={toggleFullScreen}>
            <FaExpand size={14}/>
          </button>

          <div
            className={`flex justify-center items-center bg-gray-100 px-4 py-2 rounded`}
          >
            <div className="p-2 overflow-auto">
              {seatMatrix.map((row, rowIndex) => (
                <div key={rowIndex} className={'flex'}>
                  {row.map((seat, colIndex) => (
                    <button key={colIndex}
                            style={getSeatStyle(seat)}
                            className={`flex justify-center items-center rounded-sm ${seatFocus === getSoldPackageCd(seat) && 'animate-pulse'}`}
                            onClick={() => handleSeatFocus(seat)}
                    >
                      {seat &&
                        <p className={'flex flex-col'} style={{width: 'inherit'}}>
                          <span className={'text-white'}>
                            {seat.SeatGroup + seat.SeatNo}
                          </span>
                          {!isFullScreen &&
                            <span className={`text-yellow-100 font-bold whitespace-nowrap overflow-hidden text-ellipsis`}>
                              {utils.SOLD_TICKETCD[getSoldPackageCd(seat,'asdasd')]}
                            </span>
                          }
                        </p>
                      }
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <div className="text-xs font-medium text-gray-700 flex items-center justify-center mt-6 gap-4">
            {Object.keys(orderList)?.map((ticketCd, idx) => (
              <p key={ticketCd} className={'flex items-center'}>
                <span className={`inline-block mr-1 w-2.5 h-2.5`}
                      style={{background: isSoldTicketCd(ticketCd)?.color}}
                />
                {isSoldTicketCd(ticketCd)?.title}
              </p>
            ))}
            {seatMatrix?.flat()?.some(r => r?.isCouponSeat) &&
              <p className={'flex items-center'}>
                <span className={`inline-block mr-1 w-2.5 h-2.5 bg-white border-2 border-black`}/>
                쿠폰사용좌석
              </p>
            }
          </div>
        </div>
      }
    </StyledContainer>
  )
})