export const seatInfo = {
  seatLayout : [
    {
      "LocationRow": "1",
      "LocationCol": "3",
      "SeatGroup": "A",
      "SeatNo": "1",
      "Area": "1",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "A",
      "LogicSeatNo": "1",
      "AgentSeatGroup": "A",
      "AgentSeatNo": "1",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "1",
      "LocationCol": "4",
      "SeatGroup": "A",
      "SeatNo": "2",
      "Area": "1",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "A",
      "LogicSeatNo": "2",
      "AgentSeatGroup": "A",
      "AgentSeatNo": "2",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "1",
      "LocationCol": "6",
      "SeatGroup": "A",
      "SeatNo": "3",
      "Area": "2",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "A",
      "LogicSeatNo": "3",
      "AgentSeatGroup": "A",
      "AgentSeatNo": "3",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "1",
      "LocationCol": "7",
      "SeatGroup": "A",
      "SeatNo": "4",
      "Area": "2",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "A",
      "LogicSeatNo": "4",
      "AgentSeatGroup": "A",
      "AgentSeatNo": "4",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "1",
      "LocationCol": "9",
      "SeatGroup": "A",
      "SeatNo": "5",
      "Area": "3",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "A",
      "LogicSeatNo": "5",
      "AgentSeatGroup": "A",
      "AgentSeatNo": "5",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "1",
      "LocationCol": "10",
      "SeatGroup": "A",
      "SeatNo": "6",
      "Area": "3",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "A",
      "LogicSeatNo": "6",
      "AgentSeatGroup": "A",
      "AgentSeatNo": "6",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "1",
      "LocationCol": "12",
      "SeatGroup": "A",
      "SeatNo": "7",
      "Area": "4",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "A",
      "LogicSeatNo": "7",
      "AgentSeatGroup": "A",
      "AgentSeatNo": "7",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "1",
      "LocationCol": "13",
      "SeatGroup": "A",
      "SeatNo": "8",
      "Area": "4",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "A",
      "LogicSeatNo": "8",
      "AgentSeatGroup": "A",
      "AgentSeatNo": "8",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "1",
      "LocationCol": "15",
      "SeatGroup": "A",
      "SeatNo": "9",
      "Area": "5",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "A",
      "LogicSeatNo": "9",
      "AgentSeatGroup": "A",
      "AgentSeatNo": "9",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "1",
      "LocationCol": "16",
      "SeatGroup": "A",
      "SeatNo": "10",
      "Area": "5",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "A",
      "LogicSeatNo": "10",
      "AgentSeatGroup": "A",
      "AgentSeatNo": "10",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "2",
      "LocationCol": "4",
      "SeatGroup": "B",
      "SeatNo": "11",
      "Area": "1",
      "AreaCnt": "1",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "B",
      "LogicSeatNo": "11",
      "AgentSeatGroup": "B",
      "AgentSeatNo": "11",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "2",
      "LocationCol": "6",
      "SeatGroup": "B",
      "SeatNo": "12",
      "Area": "2",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "B",
      "LogicSeatNo": "12",
      "AgentSeatGroup": "B",
      "AgentSeatNo": "12",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "2",
      "LocationCol": "7",
      "SeatGroup": "B",
      "SeatNo": "13",
      "Area": "2",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "B",
      "LogicSeatNo": "13",
      "AgentSeatGroup": "B",
      "AgentSeatNo": "13",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "2",
      "LocationCol": "9",
      "SeatGroup": "B",
      "SeatNo": "14",
      "Area": "3",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "B",
      "LogicSeatNo": "14",
      "AgentSeatGroup": "B",
      "AgentSeatNo": "14",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "X",
      "ColorR": "61",
      "ColorG": "63",
      "ColorB": "81",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "2",
      "LocationCol": "10",
      "SeatGroup": "B",
      "SeatNo": "15",
      "Area": "3",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "B",
      "LogicSeatNo": "15",
      "AgentSeatGroup": "B",
      "AgentSeatNo": "15",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "X",
      "ColorR": "61",
      "ColorG": "63",
      "ColorB": "81",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "2",
      "LocationCol": "12",
      "SeatGroup": "B",
      "SeatNo": "16",
      "Area": "4",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "B",
      "LogicSeatNo": "16",
      "AgentSeatGroup": "B",
      "AgentSeatNo": "16",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "2",
      "LocationCol": "13",
      "SeatGroup": "B",
      "SeatNo": "17",
      "Area": "4",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "B",
      "LogicSeatNo": "17",
      "AgentSeatGroup": "B",
      "AgentSeatNo": "17",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "2",
      "LocationCol": "15",
      "SeatGroup": "B",
      "SeatNo": "18",
      "Area": "5",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "B",
      "LogicSeatNo": "18",
      "AgentSeatGroup": "B",
      "AgentSeatNo": "18",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "2",
      "LocationCol": "16",
      "SeatGroup": "B",
      "SeatNo": "19",
      "Area": "5",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "B",
      "LogicSeatNo": "19",
      "AgentSeatGroup": "B",
      "AgentSeatNo": "19",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "3",
      "LocationCol": "1",
      "SeatGroup": "C",
      "SeatNo": "20",
      "Area": "1",
      "AreaCnt": "1",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "C",
      "LogicSeatNo": "1",
      "AgentSeatGroup": "C",
      "AgentSeatNo": "20",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "3",
      "LocationCol": "3",
      "SeatGroup": "C",
      "SeatNo": "21",
      "Area": "2",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "C",
      "LogicSeatNo": "21",
      "AgentSeatGroup": "C",
      "AgentSeatNo": "21",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "3",
      "LocationCol": "4",
      "SeatGroup": "C",
      "SeatNo": "22",
      "Area": "2",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "C",
      "LogicSeatNo": "22",
      "AgentSeatGroup": "C",
      "AgentSeatNo": "22",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "3",
      "LocationCol": "6",
      "SeatGroup": "C",
      "SeatNo": "23",
      "Area": "3",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "C",
      "LogicSeatNo": "23",
      "AgentSeatGroup": "C",
      "AgentSeatNo": "23",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "3",
      "LocationCol": "7",
      "SeatGroup": "C",
      "SeatNo": "24",
      "Area": "3",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "C",
      "LogicSeatNo": "24",
      "AgentSeatGroup": "C",
      "AgentSeatNo": "24",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "3",
      "LocationCol": "9",
      "SeatGroup": "C",
      "SeatNo": "25",
      "Area": "4",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "C",
      "LogicSeatNo": "25",
      "AgentSeatGroup": "C",
      "AgentSeatNo": "25",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "3",
      "LocationCol": "10",
      "SeatGroup": "C",
      "SeatNo": "26",
      "Area": "4",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "C",
      "LogicSeatNo": "26",
      "AgentSeatGroup": "C",
      "AgentSeatNo": "26",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "3",
      "LocationCol": "12",
      "SeatGroup": "C",
      "SeatNo": "27",
      "Area": "5",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "C",
      "LogicSeatNo": "27",
      "AgentSeatGroup": "C",
      "AgentSeatNo": "27",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "3",
      "LocationCol": "13",
      "SeatGroup": "C",
      "SeatNo": "28",
      "Area": "5",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "C",
      "LogicSeatNo": "28",
      "AgentSeatGroup": "C",
      "AgentSeatNo": "28",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "3",
      "LocationCol": "15",
      "SeatGroup": "C",
      "SeatNo": "29",
      "Area": "6",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "C",
      "LogicSeatNo": "29",
      "AgentSeatGroup": "C",
      "AgentSeatNo": "29",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    },
    {
      "LocationRow": "3",
      "LocationCol": "16",
      "SeatGroup": "C",
      "SeatNo": "30",
      "Area": "6",
      "AreaCnt": "2",
      "SeatStatus": "available",
      "SeatType": "normal",
      "SeatZone": "",
      "SeatSaleType": "all",
      "LogicSeatGroup": "C",
      "LogicSeatNo": "30",
      "AgentSeatGroup": "C",
      "AgentSeatNo": "30",
      "UnitType": "명",
      "DefaultUnitCnt": "0",
      "UnitCntIncrease": "0",
      "SeatColor": "흰색",
      "Mark": "",
      "ColorR": "150",
      "ColorG": "150",
      "ColorB": "150",
      "ColorHEX": "C8C8C8",
      "OutLineColorR": "",
      "OutLineColorG": "",
      "OutLineColorB": "",
      "OutLineColorHEX": ""
    }
  ]
}
