import React, { useEffect, useState } from 'react'
import { registerLocale } from 'react-datepicker'
import ko from 'date-fns/locale/ko'
import { format } from 'date-fns'
import { ScheduleTable } from '../../components/schedule/ScheduleTable'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { utils } from '../../utils/utils'
import { SalesPackageSeat } from './components/SalesPackageSeat'
import { OrderState } from './components/OrderState'
import { Helmet } from 'react-helmet-async'
import { table } from '../../api/airTableAPi'
import { seat } from '../../api/api'
import { seatInfo } from '../../assets/seatLayout'

export const Sales = () => {
  registerLocale('ko', ko) //날짜 한국어로 표시

  const [seatMatrix, setSeatMatrix] = useState([])
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [seatFocus, setSeatFocus] = useState(null)
  const [formData, setFormData] = useState({
    gender: '',
    name: '',
    phoneNumber: '',
    couponCode: [{id: null, code: null, status: null, basicPackage: null}],
    date: new Date(),
    screen: '',
    imgUrl: '',
    movie: {}
  })

  // 스케줄
  const getListApi = () => {
    setFormData(prev => ({
      ...prev, movie: {}
    }))

    const {date} = formData
    if (date) {
      const formatDate = format(new Date(date), 'yyyy-MM-dd')
      seat.list(formatDate).then(({data}) => {
        const list = data.content
        const filter = list.filter(r => r.schedule.status === 'CONFIRMED') // 확정된 스케줄만 노출
        const sort = filter.sort((a,b) => new Date(a.schedule.movieTime.scheduleStart) - new Date(b.schedule.movieTime.scheduleStart))
        setList(sort)
      })
    }
  }


  // 쿠폰 사용한 좌석 (무료, 유료 구분하기 위함)
  const getUsedCouponSeatListApi = async () => {
    const {movie: {id, movieTime}} = formData
    if (id) {
      try {
        const date = utils.formatDateTime(movieTime.scheduleStart)
        const {records} = await table.usedCouponSeatCheck(date)

        const result = records.map(item => {
          // item?.fields?.Seat가 존재하고 비어있지 않다면
          if (!item?.fields?.Seat) return []

          return item.fields.Seat.split(',').map(seat => seat.trim())
        })

        return result.flat()
      } catch (err) {
        console.error('Error get usedCouponSeat:', err)
      }
    }
  }

  // 좌석 리스트
  const getSeatListApi = async (couponSeatList) => {
    const {movie: {id, movieTime}, date} = formData

    if (id) {
      try {
        const {data:seatStatus} = await seat.getSeatsStatus(formData.movie.id)

        const payload = {
          theaterCode: 'm019',
          status: 'PAID',
          rangeType: 'schedulePeriod',
          schedulePeriod: {
            from: format(new Date(date), 'yyyy-MM-dd'),
            to: format(new Date(date), 'yyyy-MM-dd'),
          }
        }
        const {data: {content: soldList}} = await seat.orderListWithSearch(payload)
        const filterSoldList = soldList.filter( r => (r.schedule.scheduleStart) === movieTime.scheduleStart)

        let sortedSeatStatus = Object.keys(seatStatus)
        .sort((a, b) => {
          // 알파벳과 숫자를 분리
          const [letterA, numberA] = a.match(/([A-Za-z]+)(\d+)/).slice(1, 3);
          const [letterB, numberB] = b.match(/([A-Za-z]+)(\d+)/).slice(1, 3);

          // 알파벳 비교 -> 같으면 숫자 비교
          return letterA.localeCompare(letterB) || Number(numberA) - Number(numberB);
        })
        .reduce((acc, key) => {
          acc[key] = seatStatus[key];
          return acc;
        }, {});

        const seatLayout = seatInfo.seatLayout
        const maxRow = Math.max(...seatLayout.map(seat => Number(seat.LocationRow)))
        const maxCol = Math.max(...seatLayout.map(seat => Number(seat.LocationCol)))

        const newSeatMatrix = createSeatMatrix(seatLayout, maxRow, maxCol, couponSeatList, Object.entries(sortedSeatStatus), filterSoldList)
        if (JSON.stringify(newSeatMatrix) !== JSON.stringify(seatMatrix)) {
          setSeatMatrix(newSeatMatrix)
        }
      } catch (error) {
        console.error('Error fetching seat list:', error)
      }
    }
  }

  const createSeatMatrix = (seatList, maxRow, maxCol, couponSeatList, seatStatus, soldList) => {
    const matrix = Array.from({length: maxRow}, () => Array(maxCol).fill(null))
    const holdList = list.find(r => r.id === formData?.movie?.id)?.schedule?.seatHoldList

    const couponSeatSet = new Set(couponSeatList)
    const isCouponSeat = (seat) => couponSeatSet.has(`${seat?.SeatGroup}${seat?.SeatNo}`)

    const status = (seatCode) => {
      if(holdList.length > 0) {
        return holdList.includes(seatCode)
      }
    }

    const sameSeatCode = (idx) => {
      return soldList.find(r => r.seatCode === seatStatus[idx][0])
    }


    // 좌석 데이터를 배열에 배치
    seatList.forEach((seat, idx) => {
      const row = Number(seat.LocationRow) - 1
      const col = Number(seat.LocationCol) - 1

      matrix[row][col] = {
        ...seat,
        SeatStatus: (seat.SeatGroup + seat.SeatNo === seatStatus[idx][0]) && status(seatStatus[idx][0])
          ? 'HOLD'
          : seatStatus[idx][1], isCouponSeat: isCouponSeat(seat),
        priceId: sameSeatCode(idx)?.priceId || '',
        priceTitle: sameSeatCode(idx)?.priceTitle || '',
        totalAmount: sameSeatCode(idx)?.totalAmount,
        moviePrice: sameSeatCode(idx)?.moviePrice,
      }
    })

    return matrix
  }

  const handleInputChange = (event, idx) => {
    const targets = Array.isArray(event.target) ? event.target : [event.target]

    setFormData((prev) => {
      const updatedFormData = {...prev}

      targets.forEach(({name, value, id}) => {
        let updatedValue

        if (name === 'couponCode') {
          let newCouponCode = Array.isArray(prev.couponCode) ? [...prev.couponCode] : []

          if (id !== 'selectPackage') {
            newCouponCode[idx] = {
              id: null,
              code: value.toUpperCase(),
              status: utils.hasKoreanText(value) ? utils.COUPON_CHECK_TYPE.ENG : utils.COUPON_CHECK_TYPE.NONE,
              basicPackage: null
            }
          } else {
            newCouponCode[idx] = {
              ...newCouponCode[idx], basicPackage: value
            }
          }

          updatedValue = newCouponCode
        } else if (name === 'movie') {
          updatedValue = JSON.parse(value)
        } else {
          updatedValue = value
        }

        updatedFormData[name] = updatedValue
      })

      return updatedFormData
    })
  }

  const toggleFullScreen = () => {
    setIsFullScreen(prev => !prev)
  }

  const fullScreenStyle = ({
    position: 'fixed',
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    maxWidth: '100vw',
    maxHeight: '100vh',
    background: 'rgb(229,231,235)',
    zIndex: 999
  })

  const resetState = () => {
    setSeatMatrix([])
    setLoading(false)
    setSeatFocus(null)
  }

  useEffect(() => {
    resetState()
    getListApi()
  }, [formData?.date])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // usedCouponSeatList 먼저 실행
        const couponSeatList = await getUsedCouponSeatListApi()

        // 그 다음에 getSeatListApi 실행
        await getSeatListApi(couponSeatList)
      } catch (error) {
        console.error('Error fetching seat lists:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData().catch(console.error)  // Promise rejection 처리
  }, [formData?.movie?.movieTime?.scheduleStart])

  return (
    <>
      <Helmet>
        <title>MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황</title>
        <meta name="description" content="MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황"/>
        <meta property="og:title" content="MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황"/>
        <meta property="og:description" content="MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황"/>
      </Helmet>

      <main className="relative mt-4 bg-gray-200">
        <div className="xl:w-[840px] w-full m-auto h-full rounded pb-[70px]">
          <Header isReservation={false}/>

          <ScheduleTable formData={formData} setFormData={setFormData} list={list} handleInputChange={handleInputChange} setLoading={setLoading}/>

          {formData?.movie?.id &&
            <div style={isFullScreen ? fullScreenStyle : {}}>
              <SalesPackageSeat seatMatrix={seatMatrix}
                                seatFocus={seatFocus}
                                setSeatFocus={setSeatFocus}
                                loading={loading}
                                isFullScreen={isFullScreen}
                                toggleFullScreen={toggleFullScreen}
                                list={list.filter(({schedule}) => formData?.movie?.movieTime?.scheduleDate === schedule?.movieTime?.scheduleDate && formData?.movie?.movieTime?.scheduleStart === schedule?.movieTime?.scheduleStart)}
              />

              {isFullScreen && <div className={'mb-3'}/>}

              {seatMatrix?.flat().some(r=> r?.priceId) &&
                <OrderState seatMatrix={seatMatrix}
                            seatFocus={seatFocus}
                            loading={loading}
                            setSeatFocus={setSeatFocus}
                            isFullScreen={isFullScreen}
                />
              }
            </div>
          }
        </div>

        <Footer/>
      </main>
    </>
  )
}