import ajax from './ajax'

const ryseCd = 'm019'
const apiEndPoint = '/booking/v1'

export const seat = {
  list(date) {
    const payload = {
      theaterCode: ryseCd,
      from: date,
      to: date
    }

    return ajax.get(`${apiEndPoint}/schedule?${new URLSearchParams(payload)}`)
  },
  ticketListByScheduleId(id) {
    return ajax.get(`${apiEndPoint}/schedule/${id}/ticket-list`)
  },
  orderListWithSearch( search) {
    return ajax.post(apiEndPoint + '/orders/products', search, null)
  },
  getSeatsStatus(id) {
    return ajax.get(`${apiEndPoint}/reservation/${id}/seats/status??onoffline=ONLINE`)
  }

}
