import { format } from 'date-fns'
import ko from 'date-fns/locale/ko'
import StyledContainer from '../../../../components/StyledContainer'
import React from 'react'
import { utils } from '../../../../utils/utils'

export const SelectMovie = ({formData}) => {
  return (
    <StyledContainer>
      <div className="text-sm font-medium text-gray-700">
        선택한 영화
      </div>

      <div className={'mt-3 flex items-center gap-4 h-full'}>
        <div className={'rounded bg-gray-500 w-20 h-28 shrink-0'}>
          <img src={formData.imgUrl} alt={'poster'} className={'w-full h-full rounded'}/>
        </div>
        <div className={'shrink'}>
          <p className={'font-medium text-sm max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap'}>{formData?.movie?.movie.title}
          </p>
          <p className={'mt-1 text-xs text-gray-700'}>
            {format(new Date(formData?.movie?.movieTime.scheduleStart), 'yyyy.MM.dd(eee) HH:mm', {locale: ko})}
          </p>
          <p className={'mt-1 text-xs text-gray-700'}>
            모노플렉스 @라이즈 홍대
          </p>
          <p className={'mt-1 text-xs text-gray-700'}>
            {formData.movie.screenName} • {utils.showTypeCodeToLabel(formData.movie.movie.showType)}({utils.showTypeFormatCodeToLabel(formData.movie.movie.showTypeFormat)})
          </p>
        </div>
      </div>
    </StyledContainer>
  )
}