import axios from 'axios'

const api = {
  optionsToParams(options = {}, paramMap = null, sortMap = null) {
    const { sortBy, descending, page = 1, rowsPerPage = 10 } = options

    let params = {
      'page': page - 1,
      'size': rowsPerPage
    }

    if (sortBy) {
      let sortName = (sortMap != null && sortMap[sortBy] != null) ? sortMap[sortBy] : sortBy
      params['sort'] = sortName + ',' + (descending ? 'desc' : 'asc')
    }

    if (paramMap) {
      for (const [k, v] of Object.entries(paramMap)) {
        if (v || typeof v === 'boolean') {
          params[k] = v
        }
      }
    }

    return params
  },

  call(method, url, params, payload, errorHandler, headers = {}) { // headers 추가
    return new Promise((resolve, reject) => {
      axios({
        method: method,
        url: url,
        params: params, // params 처리
        data: payload,
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
        } // headers 처리
      }).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        if (errorHandler != null) {
          errorHandler(error, reject)
        } else {
          reject(error)
        }
      })
    })
  },
  get(url, params = null, errorHandler = this.defaultHandleError, headers = {}) {
    return this.call('get', url, params, null, errorHandler, headers)
  },
  post(url, payload, errorHandler = this.defaultHandleError, params, headers = {}) {
    return this.call('post', url, params, payload, errorHandler, headers)
  },
  put(url, payload, errorHandler = this.defaultHandleError) {
    return this.call('put', url, null, payload, errorHandler)
  },
  patch(url, payload, errorHandler = this.defaultHandleError, headers = {}) {
    return this.call('patch', url, null, payload, errorHandler, headers)
  },
  delete(url, params = null, errorHandler = this.defaultHandleError, payload) {
    return this.call('delete', url, params, payload, errorHandler)
  },

  defaultHandleError(error, reject) {
    if (axios.isCancel(error)) {
      reject(error)
    }
    reject(error)
  }
}

export default api