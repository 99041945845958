import { format, isBefore, subHours } from 'date-fns'

export const utils = {
  SHOW_TYPE: {
    'PST-20': '2D',
    'PST-30': '3D',
  },
  showTypeCodeToLabel: (label) => utils.SHOW_TYPE[label] ?? null,

  SHOW_TYPE_FORMAT: {
    'PSF-0100': '한국어 더빙',
    'PSF-0120': '한글 자막',
    'PSF-0130': '영문 자막',
    'PSF-0140': '일문 자막',
    'PSF-0190': '기타',
    'PSF-0191': '기타-화면테스트',
    'PSF-0192': '기타-음향테스트'
  },
  showTypeFormatCodeToLabel: (label) => utils.SHOW_TYPE_FORMAT[label] ?? null,


  COUPON_CHECK_TYPE: {
    NONE: '', DUPLICATE: '중복된 쿠폰입니다.', FAIL: '등록되지 않은 쿠폰입니다.', SUCCESS: '사용 가능한 쿠폰입니다.', ENG: '영어로 입력해야 합니다.'
  },
  SOLD_TICKETCD: {
    '67639ff6fba69f06fc638a0b': 'B_PKG-1', // 베이직 패키지 1 : 컵과일
    '6764f9effba69f06fc638a22': 'B_PKG-2', // 베이직 패키지 2 : 디저트 3종
    '6764fa44fba69f06fc638a23': 'PKG-1', // 패키지 1 : 치맥세트
    '6764fab7fba69f06fc638a24': 'PKG-2-R', // 패키지 2 : 레드와인
    '6764fb00fba69f06fc638a25': 'PKG-2-W', // 패키지 2 : 화이트와인
    // '002460': 'NORMAL' // 일반
  },
  SEAT_COLOR_TYPE: {
    NORMAL: {color: '#adadad', value: 'normal', label: '1인석', order: 1},
    COUPLE: {color: '#D8565F', value: 'couple', label: '커플석', order: 2},
    PAID: {color: '#3D3F51', value: 'PAID', label: '예매좌석', order: 3},
    HOLD: {color: '#986140', value: 'HOLD', label: '보류좌석', order: 4}
  },
  seatColor(seat) {
    if (!seat) return 'transparent'
    const {SeatStatus, SeatType} = seat
    const {NORMAL, COUPLE, PAID, HOLD} = this.SEAT_COLOR_TYPE

    const statusColor = {
      [PAID.value]: PAID.color,
      [HOLD.value]: HOLD.color
    }

    // 우선 SeatStatus로 체크
    if (statusColor[SeatStatus]) {
      return statusColor[SeatStatus]
    }

    const typeColor = {
      [NORMAL.value]: NORMAL.color,
      [COUPLE.value]: COUPLE.color
    }

    // SeatType 체크
    return typeColor[SeatType] || 'transparent'
  },
  packageCell() {
    const cell = [
      {title: 'B_PKG-1', label: '베이직 패키지 1', sub: '컵과일', color: '#72ab1e'},
      {title: 'B_PKG-2', label: '베이직 패키지 2', sub: '디저트 3종', color: '#d8569b'},
      {title: 'PKG-1', label: '패키지 1', sub: '치맥세트', color: '#c77f21'},
      {title: 'PKG-2-R', label: '패키지 2-R', sub: '레드와인 & 샤키테리세트', color: '#750000'},
      {title: 'PKG-2-W', label: '패키지 2-W', sub: '화이트와인 & 샤키테리세트', color: '#65ab8e'},
      {title: '합계', label: 'TOTAL'}
    ]

    cell.forEach(r => {
      r.value = r.title // value 추가

      const ticketCodes = Object.keys(this.SOLD_TICKETCD).filter(
        key => this.SOLD_TICKETCD[key] === r.title
      )

      r.code = ticketCodes[0] || 'TOTAL'
    })

    return cell
  },
  preventScroll() {
    const currentScrollY = window.scrollY
    document.body.style.position = 'fixed'
    document.body.style.width = '100%'
    document.body.style.top = `-${currentScrollY}px` // 현재 스크롤 위치
    document.body.style.overflowY = 'scroll'
    return currentScrollY
  },
  allowScroll() {
    document.body.style.position = ''
    document.body.style.width = ''
    document.body.style.top = ''
    document.body.style.overflowY = ''
  },
  hasKoreanText(text) {
    return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(text)
  },
  isTimeBefore(row, pathname) {
    const currentDate = new Date()
    const dataDate = row?.movieTime?.scheduleStart

    if (pathname === '/') {
      // 현재 시간에서 1시간 전으로 설정
      const oneHourBefore = subHours(dataDate, 1)

      // 현재 시간이 영화
      // 시작 1시간 이전보다 전인지 확인
      return isBefore(currentDate, oneHourBefore)
    }

    // `dataDate`와 `currentDate`를 비교하기 전에 포맷팅된 `currentDate`를 `Date` 객체로 비교
    const formattedDate = new Date(format(currentDate, 'yyyy-MM-dd HH:mm')); // 문자열이 아닌 Date 객체로 변환
    const movieTime = new Date(dataDate); // `dataDate`가 문자열이라면 Date 객체로 변환

    return isBefore(formattedDate, movieTime); // 정확한 날짜 비교
  },
  hasDuplicateByKey(coupon, key) {
    if (coupon?.length > 1) {
      return coupon?.some((r, idx) => r?.code === coupon[key]?.code && idx !== key)
    }
  },
  formatDateTime(scheduleStart) {
    const localDate = new Date(scheduleStart)

    const utcDate = new Date(Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds()
    ))

    return utcDate.toISOString()
  }

}