import StyledContainer from '../../../components/StyledContainer'
import { FaMinus, FaPlus, FaRegCheckCircle } from 'react-icons/fa'
import { SpinLoading } from '../../../assets/SpinLoading'
import React, { Fragment, useState } from 'react'
import { utils } from '../../../utils/utils'
import { table } from '../../../api/airTableAPi'
import { Package } from './Package'

export const Coupon = ({formData, setFormData, handleInputChange}) => {
  const [loading, setLoading] = useState([])

  const formatCouponCode = (value) => {
    if (!value) return

    return value
    .replace(/[^a-zA-Z0-9]/g, '') // 문자와 숫자만 허용
    .toUpperCase() // 모든 문자를 대문자로 변환
    .replace(/(.{4})/g, '$1-') // 4글자마다 하이픈 추가
    .replace(/-$/, '') // 마지막 하이픈 제거
  }

  const handleCouponCheck = async (id) => {
    await getCouponCheck(id)
  }

  const updateCouponLoading = (prev, id, status) => {
    const exists = prev.some((r, i) => r.id === id)
    return exists
      ? prev.map((r, i) => r.id === id ? {...r, status} : r)
      : [...prev, {id, status}]
  }

  // 쿠폰 조회
  const getCouponCheck = async (id) => {
    const {couponCode} = formData

    if (utils.hasDuplicateByKey(couponCode, id)) {
      return alert('동일한 쿠폰을 입력할 수 없습니다.')
    }

    setLoading(prev => updateCouponLoading(prev, id, true))

    const {records: duplicateRecords} = await table.couponDuplicateCheck(couponCode[id].code)

    if (duplicateRecords.length === 0) {
      const {records} = await table.couponCheck(couponCode[id].code)

      if (records.length === 0) {
        updatedCouponError(id, utils.COUPON_CHECK_TYPE.FAIL)
      } else {
        setFormData(prev => {
          const newCouponCode = prev.couponCode.map((coupon, index) => {
            // 현재 인덱스가 추가하고자 하는 인덱스와 일치하는 경우
            if (index === id) {
              return {
                ...coupon,
                id: records[0].id // id 업데이트
              }
            }
            return coupon // 나머지 쿠폰은 그대로 반환
          })

          return {
            ...prev,
            couponCode: newCouponCode
          }
        })
        updateCouponSuccess(id)
      }
    } else {
      updatedCouponError(id, utils.COUPON_CHECK_TYPE.DUPLICATE)
    }
  }

  const updatedCouponError = (id, status) => {
    setFormData(prev => ({
      ...prev,
      couponCode: prev.couponCode.map((coupon, index) =>
        index === id ? {...coupon, status} : coupon
      )
    }))
    setLoading(prev => updateCouponLoading(prev, id, false))
  }

  const updateCouponSuccess = (id) => {
    setFormData(prev => ({
      ...prev,
      couponCode: prev.couponCode.map((coupon, index) =>
        index === id ? {...coupon, status: utils.COUPON_CHECK_TYPE.SUCCESS} : coupon
      )
    }))
    setLoading(prev => updateCouponLoading(prev, id, false))
  }

  const addCouponCnt = (e) => {
    e.preventDefault()

    setFormData(prev => ({...prev, couponCode: [...prev.couponCode, {id: null, code: null}]}))
  }

  const deleteCouponCnt = (e, idx) => {
    e.preventDefault()
    if (formData.couponCode.length > 1) {
      const filteredCouponCode = formData.couponCode.filter((coupon, i) => i !== idx)
      setFormData((prev) => ({...prev, couponCode: filteredCouponCode}))
    }
  }

  const getCheckCircleClass = (idx) => {
    const status = formData.couponCode.find((r, i) => i === idx)?.status
    return status === utils.COUPON_CHECK_TYPE.SUCCESS
      ? 'text-green-500'
      : (status === utils.COUPON_CHECK_TYPE.FAIL || status === utils.COUPON_CHECK_TYPE.DUPLICATE || status === utils.COUPON_CHECK_TYPE.ENG)
        ? 'text-red-300'
        : 'text-gray-500'
  }

  const getInputClass = (idx) => {
    const status = formData.couponCode.find((r, i) => i === idx)?.status

    return `w-full border-0 border-b ps-10 focus:outline-none focus:ring-0 bg-white text-sm text-gray-700 transition ease-linear ${
      status === utils.COUPON_CHECK_TYPE.SUCCESS ? 'border-green-500 focus:border-green-500' :
        (status === utils.COUPON_CHECK_TYPE.FAIL || status === utils.COUPON_CHECK_TYPE.DUPLICATE || status === utils.COUPON_CHECK_TYPE.ENG) ? 'border-red-300 focus:border-red-300' : 'border-gray-200 focus:border-black'
    }`
  }

  const getButtonClass = (idx) => {
    const isSuccess = formData.couponCode.find((r, i) => i === idx)?.status === utils.COUPON_CHECK_TYPE.SUCCESS
    return `bg-black hover:bg-gray-700 inline-flex text-white font-medium text-sm whitespace-pre px-3.5 py-1.5 rounded-full items-center disabled:bg-gray-400 transition ease-linear ${isSuccess ? 'disabled:bg-green-200' : ''}`
  }

  const isButtonDisabled = ((idx) => {
    const couponCode = formData?.couponCode[idx]?.code || ''

    return couponCode.length === 0 || // 빈 문자열 체크
      couponCode.length !== 14 ||
      utils.hasKoreanText(couponCode) || // 한글 체크
      formData.couponCode.find((r, id) => id === idx)?.status === utils.COUPON_CHECK_TYPE.SUCCESS || // 성공 체크
      loading.find(r => r.id === idx)?.status // 로딩 체크
  })

  return (
    <StyledContainer>
      <Package/>

      <div className={'flex justify-between mb-4'}>
        <div>
          <div className={'flex items-center gap-1'}>
            <label htmlFor="Email" className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
              쿠폰 번호
            </label>
          </div>
          <div className={'mt-1 text-xs text-gray-400'}>개수 : {formData?.couponCode?.length}</div>
        </div>

        <div className={'flex items-center'}>
          <div className={'flex items-center gap-2'}>
            <button
              className={`bg-black hover:bg-gray-700 inline-flex text-white font-medium text-sm whitespace-pre p-1.5 rounded-md items-center disabled:bg-gray-400 transition ease-linear`}
              onClick={(e) => addCouponCnt(e)}>
              <FaPlus size={14}/>
            </button>
          </div>
        </div>
      </div>

      <hr/>

      <div className={'flex flex-col mb-5'}>
        {formData?.couponCode.map((coupon, idx) => (
          <Fragment key={idx}>
            <div
              className={`flex flex-col items-center md:flex-row mt-5 gap-4 ${idx === formData?.couponCode.length - 1 ? 'border-none' : 'pb-4 border-b border-dashed border-gray-200'}`}>

              <div className="flex w-full md:w-auto items-center justify-between gap-4 grow">
                <button onClick={(e) => deleteCouponCnt(e, idx)}
                        className={`bg-black hover:bg-gray-700 inline-flex text-white font-medium text-sm whitespace-pre p-1.5 rounded-md items-center disabled:bg-gray-400 transition ease-linear`}
                        disabled={formData?.couponCode.length < 2}
                >
                  <FaMinus size={14}/>
                </button>
                <div className="w-full">
                  <div className={'flex relative'}>
                    <p className="pointer-events-none absolute inset-y-0 start-0 grid w-10 place-content-center text-gray-500">
                      <FaRegCheckCircle className={getCheckCircleClass(idx)}/>
                    </p>
                    <input
                      type="text"
                      id="couponCode"
                      name="couponCode"
                      placeholder="값을 입력해주세요."
                      value={formatCouponCode(coupon?.code) || ''}
                      onChange={(e) => handleInputChange(e, idx)}
                      maxLength="14"
                      required
                      className={getInputClass(idx)}
                    />
                  </div>
                  {coupon.status && (
                    <p
                      className={`inline-block mt-2 text-xs ${coupon.status === utils.COUPON_CHECK_TYPE.SUCCESS ? 'text-green-500' : 'text-red-400'}`}>
                      {coupon.status}
                    </p>
                  )}
                </div>
                <button
                  type="button"
                  className={getButtonClass(idx)}
                  onClick={() => handleCouponCheck(idx)}
                  disabled={isButtonDisabled(idx)}
                >
                  {loading.find(r => r.id === idx)?.status ? (
                    <SpinLoading width="w-5" height="h-5" textColor={'text-white'}/>
                  ) : (
                    <span className={formData.couponCode.find(r => r.id === idx)?.status === utils.COUPON_CHECK_TYPE.SUCCESS ? 'text-white' : ''}>
                    조회
                  </span>
                  )}
                </button>
              </div>

              <div className={'w-full md:w-auto'}>
                <select
                  name="couponCode"
                  id="selectPackage"
                  required
                  className="w-full bg-white text-xs rounded border-gray-300 text-gray-700 sm:text-sm disabled:bg-gray-200 disabled:text-gray-500"
                  disabled={formData.couponCode.find((r, i) => i === idx)?.status !== utils.COUPON_CHECK_TYPE.SUCCESS}
                  onChange={(e) => handleInputChange(e, idx)}
                >
                  <option value="">:: 패키지 선택 (쿠폰 조회 후 선택) ::</option>
                  <option name="basicPackage" value="베이직 패키지_1">베이직 패키지 1번</option>
                  <option name="basicPackage" value="베이직 패키지_2">베이직 패키지 2번</option>
                </select>
              </div>

            </div>
          </Fragment>
        ))}
      </div>

      <hr/>

    </StyledContainer>
  )
}