import StyledContainer from '../../../components/StyledContainer'
import React, { Fragment, useEffect, useState } from 'react'
import { SpinLoading } from '../../../assets/SpinLoading'
import { utils } from '../../../utils/utils'

export const OrderState = ({seatMatrix, seatFocus, setSeatFocus, isFullScreen, loading}) => {
  const [orderList, setOrderList] = useState({})
  const [totalLength, setTotalLength] = useState(null)
  const [totalAmount, setTotalAmount] = useState(null)

  const sumSoldSeatCnt = () => {
    let totalLen = 0
    let totalAmt = 0
    const data = seatMatrix?.flat()?.reduce((acc, cur) => {
      if (cur?.priceId) {
        const {priceId, totalAmount} = cur
        acc[priceId] = acc[priceId] || []
        acc[priceId].push(cur)
        totalLen += 1
        totalAmt += parseInt(totalAmount)
      }
      return acc
    }, {})

    const sortedData = Object.keys(data)
    .sort((a, b) => a.localeCompare(b)) // 키를 오름차순으로 정렬
    .reduce((sortedAcc, key) => {
      sortedAcc[key] = data[key].sort((seatA, seatB) =>
        (seatA.SeatGroup + seatA.SeatNo).localeCompare(seatB.SeatGroup + seatB.SeatNo)
      )
      return sortedAcc
    }, {})

    setOrderList(sortedData)
    setTotalLength(totalLen.toLocaleString())
    setTotalAmount(totalAmt.toLocaleString())
  }

  const getSetColor = (r) => {
    const pointer = !!orderList[r.code]?.length && 'cursor-pointer'
    const focus = seatFocus === r.code ? `animate-pulse` : ``
    return `${pointer} ${focus}`
  }

  const getCellStyle = (r, head = false) => {
    const baseColor = '#1f2937'
    const isFocused = seatFocus === r.code

    return {
      color: head ? r.color : (baseColor),
      background: isFocused ? `${r.color}10` : ''
    }
  }

  const isSeatFocus = seatFocus ? utils.packageCell().find(r => r?.code === seatFocus) : undefined

  const handleSeatFocus = (code) => {
    if (orderList[code]?.length) {
      setSeatFocus(prev => (prev === code ? null : code))
    }
  }

  const salesSeat = (seat, packageCode, type) => {
    const {SeatGroup, SeatNo, isCouponSeat} = seat

    if (type === 'coupon' && isCouponSeat) {
      return SeatGroup + SeatNo
    }

    if (type === 'sales' && !isCouponSeat) {
      return SeatGroup + SeatNo
    }

    return null
  }

  useEffect(() => {
    setOrderList([])
    if (seatMatrix?.flat()?.length !== 0) {
      sumSoldSeatCnt()
    }
  }, [seatMatrix])

  return (
    <StyledContainer margin={!isFullScreen}>
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium text-gray-700">
          오더 현황
        </div>
        {utils.packageCell().find(r => r.code === seatFocus) &&
          <p className={'text-xs text-gray-400'}>
            선택한 좌석 패키지 : <span style={{color: isSeatFocus ? isSeatFocus.color : 'gray'}}>{isSeatFocus?.title}</span>
          </p>
        }
      </div>


      {loading ?
        <div
          className="flex items-center justify-center w-full h-36">
          <SpinLoading width={'w-8'} height={'h-8'} textColor={'text-gray-500'}/>
        </div>
        :
        <div className="mt-4 overflow-x-auto rounded-lg border border-gray-200">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm table-fixed">
            <thead className="text-center bg-gray-50">
            <tr>
              <th className="sticky inset-y-0 start-0 bg-gray-50 px-3 py-2 w-24"></th>
              {utils.packageCell().map((r, id) => (
                <Fragment key={r.code}>
                  {orderList[r.code] && (
                    <th
                      key={r.title}
                      className={`whitespace-nowrap px-4 py-2 font-medium text-xs text-gray-900 ${getSetColor(r)} w-32`}
                      style={getCellStyle(r, true)}
                      onClick={() => handleSeatFocus(r.code)}
                    >
                      <p className={`flex flex-col gap-0.5`}>
                        <span>{r.title}</span>
                        <span className={'text-[10px]'}>({r.sub})</span>
                      </p>
                    </th>
                  )}
                  {r.code === 'TOTAL' && (
                    <th className={'whitespace-nowrap px-4 py-2 font-medium text-xs text-gray-900 w-32'}>
                      {r.title}
                    </th>
                  )}
                </Fragment>
              ))}
            </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
            <tr className={`text-center bg-white text-xs`}>
              <td className={`sticky inset-y-0 start-0 bg-white px-3 py-2 w-24`}>수량</td>
              {utils.packageCell().map((r, index) => (
                <Fragment key={`fragment-${index}-${r.code}`}>
                  {orderList[r.code] && (
                    <td
                      key={r.title}
                      className={`whitespace-nowrap px-4 py-2 text-gray-800 ${getSetColor(r)} w-32`}
                      style={getCellStyle(r)}
                      onClick={() => handleSeatFocus(r.code)}
                    >
                      {orderList[r.code]?.length || '-'}
                    </td>
                  )}
                  {r.code === 'TOTAL' && (
                    <td className={'whitespace-nowrap px-4 py-2 text-gray-800 w-32'}>
                      {totalLength}
                    </td>
                  )}
                </Fragment>
              ))}
            </tr>

            <tr className={`text-center bg-white text-xs`}>
              <td className={`sticky inset-y-0 start-0 bg-white px-3 py-2 w-24`}>총합</td>
              {utils.packageCell().map((r, index) => (
                <Fragment key={`fragment-${index}-${r.code}`}>
                  {orderList[r.code] && (
                    <td
                      key={r.title}
                      className={`whitespace-nowrap px-4 py-2 text-gray-800 ${getSetColor(r)} w-32`}
                      style={getCellStyle(r)}
                      onClick={() => handleSeatFocus(r.code)}
                    >
                      {(orderList[r.code]?.length * parseInt(orderList[r.code][0]?.totalAmount))?.toLocaleString() || '-'}
                    </td>
                  )}
                  {r.code === 'TOTAL' && (
                    <td className={'whitespace-nowrap px-4 py-2 text-gray-800 w-32'}>
                      {totalAmount}
                    </td>
                  )}
                </Fragment>
              ))}
            </tr>

            <tr className={`text-center bg-white text-xs`}>
              <td className={`sticky inset-y-0 start-0 bg-white px-3 py-2 w-24`}>판매 좌석</td>
              {utils.packageCell().map((r, index) => (
                <Fragment key={`fragment-${index}-${r.code}`}>
                  {orderList[r.code] && (
                    <td
                      key={r.title}
                      className={`whitespace-nowrap px-4 py-2 text-gray-800 ${getSetColor(r)} w-32`}
                      style={getCellStyle(r)}
                      onClick={() => handleSeatFocus(r.code)}
                    >
                      {orderList[r.code].some(r => !r.isCouponSeat) ? (
                        <div className={'flex gap-1 justify-center items-start flex-wrap'}>
                          {orderList[r.code].map((order) => (
                            <Fragment key={order?.SeatGroup + order?.SeatNo}>
                              {salesSeat(order, r.code, 'sales') && (
                                <div className={'flex flex-wrap basis-9'}>
                                  <div
                                    className={`text-[10px] w-full px-1.5 py-0.5 rounded-sm text-white`}
                                    style={{background: r.color}}
                                  >
                                    {salesSeat(order, r.code, 'sales')}
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          ))}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                  )}
                  {r.code === 'TOTAL' && (
                    <td className={'whitespace-nowrap px-4 py-2 text-gray-800 w-32'}>-</td>
                  )}
                </Fragment>
              ))}
            </tr>

            <tr className={`text-center bg-white text-xs`}>
              <td className={`sticky inset-y-0 start-0 bg-white px-3 py-2 w-24`}>쿠폰 좌석</td>
              {utils.packageCell().map((r, index) => (
                <Fragment key={`fragment-${index}-${r.code}`}>
                  {orderList[r.code] && (
                    <td
                      key={r.title}
                      className={`whitespace-nowrap px-4 py-2 text-gray-800 ${getSetColor(r)} w-32`}
                      style={getCellStyle(r)}
                      onClick={() => handleSeatFocus(r.code)}
                    >
                      {orderList[r.code].some(r => r.isCouponSeat) ? (
                        <div className={'flex gap-1 justify-center items-start flex-wrap'}>
                          {orderList[r.code].map((order) => (
                            <Fragment key={order?.SeatGroup + order?.SeatNo}>
                              {salesSeat(order, r.code, 'coupon') && (
                                <div className={'flex flex-wrap basis-9'}>
                                  <div
                                    className={`text-[10px] w-full px-1.5 py-0.5 rounded-sm text-white border-2 border-black`}
                                    style={{background: r.color}}
                                  >
                                    {salesSeat(order, r.code, 'coupon')}
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          ))}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                  )}
                  {r.code === 'TOTAL' && (
                    <td className={'whitespace-nowrap px-4 py-2 text-gray-800 w-32'}>-</td>
                  )}
                </Fragment>
              ))}
            </tr>
            </tbody>
          </table>
        </div>

      }
    </StyledContainer>
  )
}